.pick_category-title {
  width: 100%;
  margin-bottom: calc(var(--vh, 1vh) * 4);
  font-size: calc(var(--vh, 1vh) * 2);
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pick_category-search {
  background-image: url('../../assets/search.png');
  width: calc(var(--vh, 1vh) * 4.5);
  height: calc(var(--vh, 1vh) * 4.5);
  left: calc(var(--vh, 1vh) * 1);
  top: calc(var(--vh, 1vh) * 2.5);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.pick_category-err {
  color: red;
  margin-top: calc(var(--vh, 1vh) * 1);
}

.pick_category-suggestion_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pick_category-suggestion_title {
  font-size: calc(var(--vh, 1vh) * 4);
  margin-bottom: calc(var(--vh, 1vh) * 2);
  margin-top: calc(var(--vh, 1vh) * 2);
}

.pick_category-suggestion_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--vh, 1vh) * 60);
  position: relative;
  width: 100vw;
}
.pick_category-suggestion_container_empty {
  height: 0;
}

.pick_category-btn-container {
  height: calc(var(--vh, 1vh) * 8);
  width: calc(var(--vh, 1vh) * 30);
}

.pick_category-suggestion-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pick_category-suggestion {
  background-image: url('../../assets/actionBtnSecondary.webp');
  width: calc(var(--vh, 1vh) * 32);
  height: calc(var(--vh, 1vh) * 7.24324324);
  transition: 0.3s transform, 0.3s filter;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--vh, 1vh) * 4);
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  position: relative;
}

.pick_category-suggestion:active {
  filter: brightness(1.1);
  transform: scale(0.8);
}

.pick_category-suggestion-gold {
  background-image: url('../../assets/goldenBtn.webp');
  width: calc(var(--vh, 1vh) * 38.3561643836);
  height: calc(var(--vh, 1vh) * 10);
  margin-top: calc(var(--vh, 1vh) * 4);
}

.pick_category-highscore_container {
  position: absolute;
  left: calc(var(--vh, 1vh) * -2);
  display: flex;
}

.pick_category-highscore_container-gold {
  position: absolute;
  display: flex;
  top: calc(var(--vh, 1vh) * -5);
}

.pick_category-highscore_avatar {
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 7);
}

.pick_category-highscore_text_container {
  position: absolute;
  top: calc(var(--vh, 1vh) * -2);
  z-index: 1;
  background-image: url('../../assets/Crown.webp');
  width: calc(var(--vh, 1vh) * 6.4);
  height: calc(var(--vh, 1vh) * 4);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pick_category-highscore_text_container-gold {
  background-image: url('../../assets/goldenCrown.webp');
  margin-left: calc(var(--vh, 1vh) * 0.2);
}

.pick_category-highscore_text {
  margin-top: calc(var(--vh, 1vh) * 1.4);
  color: #ffb743;
  width: 80%;
  height: 80%;
}

.pick_category-checkmark {
  background-image: url('../../assets/checkmark.webp');
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: calc(var(--vh, 1vh) * -2);
}

.pick-category-or {
  margin-bottom: calc(var(--vh, 1vh) * 1);
  font-size: calc(var(--vh, 1vh) * 2);
}

.pick-category-play-specifc-topic {
  color: #6dfffd;
  font-size: calc(var(--vh, 1vh) * 3);
  margin-top: calc(var(--vh, 1vh) * 1);
}
