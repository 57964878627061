.active-drawing-simultaneous_content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;
}

.active-drawing-simultaneous_sentence-puzzle-summary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: calc(var(--vh, 1vh) * 2) 3vw;
  max-width: 80%;
}

.active-drawing-simultaneous_sentence-puzzle-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /*max-width: 80%;*/
  text-align: center;
  font-size: 20pt;
  line-height: 28pt;
  font-weight: normal;
}

.active-drawing-simultaneous_double {
  background-image: url('../../../assets/powerUpDouble.png');
  width: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 10);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.active-drawing-simultaneous_5050 {
  background-image: url('../../../assets/powerUp5050.png');
  width: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 10);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s, filter 0.3s;
}

.active-drawing-simultaneous_powerups {
  margin-top: calc(var(--vh, 1vh) * 2);
  border-image-source: linear-gradient(
    90deg,
    rgba(17, 26, 52, 1) 0%,
    rgba(25, 77, 175, 1) 50%,
    rgba(17, 26, 52, 1) 100%
  );
  border-top: 3px solid;
  border-image-slice: 1;
  width: 100%;
  padding-top: calc(var(--vh, 1vh) * 3);
  justify-content: space-evenly;
  display: flex;
  transition: transform 0.3s, filter 0.3s;
}

.active-drawing-simultaneous_powerup_disabled {
  transform: scale(0.7);
  filter: brightness(0.5);
}

.active-drawing-simultaneous_powerup_active {
  animation: active-drawing-simultaneous_powerup_active-anim 2.5s infinite;
}

@keyframes active-drawing-simultaneous_powerup_active-anim {
  0% {
    filter: brightness(1);
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
    filter: brightness(1.5);
  }
  50% {
    filter: brightness(1);
    transform: scale(1);
  }
}

.active-drawing-simultaneous_title {
  font-size: calc(var(--vh, 1vh) * 3);
  text-align: center;
  flex-grow: 1;
}
