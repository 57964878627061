.loader-template_root {
  margin-top: -6vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}
.loader-template-background-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 65vw;
}
.loader-template-background-img-content {
  width: 100vw;
}
.loader-template_container {
  position: relative;
}
