.submittable-input_root {
  background-image: url('../../assets/inputBox.png');
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  height: calc(var(--vh, 1vh) * 8);
  display: flex;
  position: relative;
  width: calc(var(--vh, 1vh) * 40);
  margin-bottom: calc(var(--vh, 1vh) * 2);
  justify-content: center;
}

.submittable-input_input {
  border: none;
  background: transparent;
  font-size: calc(var(--vh, 1vh) * 4);
  width: 78%;
  margin-bottom: calc(var(--vh, 1vh) * 5);
  margin-top: calc(var(--vh, 1vh) * 5);
  color: white;
  outline: 0 none transparent;
}

.submittable-input_input_with_icon {
  width: 70%;
  margin-left: calc(var(--vh, 1vh) * 3);
}

.submittable-input_warning {
  position: absolute;
  bottom: -10%;
  color: red;
}
.submittable-input_x {
  background-image: url('../../assets/submittableInputX.png');
  height: calc(var(--vh, 1vh) * 2.5);
  width: calc(var(--vh, 1vh) * 2.5);
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: calc(var(--vh, 1vh) * 1.5);
  animation: submittable-input_x-appear 0.3s;
}

@keyframes submittable-input_x-appear {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.submittable-input_input_container {
  width: 50%;
  height: 31%;
  position: absolute;
  bottom: -14%;
}

.submittable-input_submit {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/actionBtn.webp');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s, filter 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 3);
}

.submittable-input_submit:active {
  transform: scale(0.9);
  filter: brightness(110%);
}

.submittable-input_submit_disabled {
  filter: brightness(50%);
  transform: scale(0.8);
}
