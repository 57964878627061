.game_button_with_user-selected {
  animation: game_button_with_user-selected-anim 2s infinite;
}

@keyframes game_button_with_user-selected-anim {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(1);
  }
}

.game_button_with_user-icon {
  animation: game_button_with_user-appear 0.3s;
}

@keyframes game_button_with_user-appear {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.game_button_with_user-icon_lg {
  width: 150px;
  height: 150px;
  margin-right: 40px;
  position: absolute;
  left: -30px;
}

.game_button_with_user-icon_sm {
  width: 50px;
  height: 60px;
  position: absolute;
  background-position: center;
}
