.root {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./playerFrame.webp');
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gold {
  background-image: url('./goldenFrame.webp');
}

.imageWithMask {
  width: 90%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  mask-image: url('./playerFrameMask.webp');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: #111a38;
}
