.winner_scene-title {
  margin-top: calc(var(--vh, 1vh) * 3);
}

.winner_scene-subtitle {
  margin-top: calc(var(--vh, 1vh) * 2);
  font-size: calc(var(--vh, 1vh) * 3);
}

.winner_scene-stars {
  background-image: url('../assets/doodleDash/stars.webp');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(var(--vh, 1vh) * 45);
  height: calc(var(--vh, 1vh) * 33);
}

.winner_scene-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.winner_scene-thumb {
  background: url('../assets/thumbs-up.png') no-repeat;
  width: 5vh;
  height: 5vh;
  background-size: contain;
}

.winner_scene-thumbs-up {
}

.winner_scene-thumbs-down {
  transform: rotate(180deg);
}
